import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const modules = require.context('./modules', true, /\.js$/)
let modulesObj = {}
modules.keys().forEach(fileName => {
  let module = modules(fileName).default
  modulesObj[fileName.replace(/^\.\/(.*)\.\w+$/, '$1')] = module
})
export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token')
  },
  getters: {
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload
    }
  },
  actions: {
  },
  modules: {
    ...modulesObj
  }
})
