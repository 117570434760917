import request from '@/utils/request'
export const login = params => request({
    // 登录
    url: '/auth/open/login',
    method: 'post',
    data: params
})
export const getUserInfo = params => request({
    //获取用户信息
    url: '/adminUser/loginInfo',
    method: 'get',
    params: params
})
export const getSyncTime = params => request({
    //获取用户信息
    url: '/menu/lastSync',
    method: 'get',
    params: params
})
export const init = params => request({
    //获取用户信息
    url: '/menu/init',
    method: 'post',
    data: params
})
export const menuTree = params => request({
    //获取当前账号权限信息
    url: '/permission/menus/login',
    method: 'get',
    params: params
})
