import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登录'
    },
  },


]
let noPermission = {
  path: "*",
  name: 'NotFound',
  component: () => import('@/views/no.vue'),
  meta: {
    title: '抱歉当前账号没有该页面权限'
  },
}
Vue.use(VueRouter)




const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
  mode: 'history',
  routes: routes,
  base: process.env.BASE_URL,
})
document.title = '路米雷特'
const getRouters = (arr) => arr.flatMap(item => item.children)
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  let token = localStorage.getItem('token')
  if (token) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {

      let currentRouters = router.getRoutes()
      if (currentRouters.length > 1) {
        next()
      } else {
        try {
          store.dispatch('accessRouter').then(res => {
            let permissionRouter = getRouters(res)
            if (permissionRouter.length) {
              router.addRoute({
                path: '/',
                name: 'layout',
                redirect: permissionRouter[0].path,
                component: () => import('@/views/layout/index.vue'),
                children: [...permissionRouter, {
                  path: '/message',
                  name: '消息列表',
                  component: () => import('@/views/message/index.vue'),
                  meta: {
                    title: '消息列表'
                  },

                }]
              })
            }
            setTimeout(() => {
              router.addRoute(noPermission)
              next({ ...to, replace: true })
            }, 500);
          })

          // next()
        } catch (e) {
          next()
        }
      }
    }
  } else {
    if (to.path !== '/login') {
      next('/login')
    } else {
      next()
    }
  }

})
export default router
