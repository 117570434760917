export default {
    name: '数据中心',
    childKey:['/dataSet'],
    children: [
        {
            name: 'Dashboard',
            path: '/dataSet',
            redirect: '/dataSet/dashboard',
            meta: {
                title: 'Dashboard',
                icon: 'dashboard'
            },
            component: () => import('@/views/dataSet/index'),
            children: [{
                name: '数据看板',
                path: '/dataSet/dashboard',
                meta: {
                    title: '数据看板',
                },
                component: () => import('@/views/dataSet/dashboard/index')
            },
            ]
        },
    ]
}