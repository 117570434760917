<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script >
import zhCN from "ant-design-vue/es/locale/zh_CN"; //引入antd中文包
import moment from "moment"; //引入moment
moment.locale("zh-cn"); //配置moment中文环境
export default {
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>
<style lang="less">
#app {
  font-family: Public Sans, sans-serif, -apple-system, blinkmacsystemfont,
    Segoe UI, roboto, Helvetica Neue, arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", Segoe UI Symbol !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #0a1119;
}
</style>
