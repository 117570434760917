import axios from 'axios'
import { message } from 'ant-design-vue'
import router from '@/router/index'
axios.defaults.timeout = 600000;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
import store from '@/store';
// 添加请求拦截器
axios.interceptors.request.use((config) => {
    config.headers["Authorization"] = store.state.token
    return config;
}, function (error) {
    return Promise.reject(error);
});
// 添加响应拦截器
axios.interceptors.response.use((response) => {
    if (response.data.success) {
        return response;
    } else {
        if (response.data.code === 401) {
            localStorage.clear()
            sessionStorage.clear()
            message.error('登录已过期，即将返回登录页')
            store.commit("INIT_STORE_STATE");
            setTimeout(() => {
                location.reload()
                router.push({ path: "/login" })
            }, 2000);
            return Promise.resolve(response);
        } else if (response.data.code === 1102 || response.data.code === 1101) {
            message.error(response.data.msg)
            return Promise.resolve(response);

        }
        message.error(response.data.msg)
        return Promise.resolve(response);
    }

}, (error) => {
    return Promise.reject(error);
});
export default axios