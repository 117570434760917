export default {
  name: '工作台',
  childKey: ['/workbench', '/official'],
  children: [
    {
      name: '工作台',
      path: '/workbench',
      redirect: '/workbench/workSpace',
      meta: {
        title: 'dashboard',
        icon: 'workbench'
      },
      component: () => import('@/views/dashboard/index'),
      children: [{
        name: '工作提醒',
        path: '/workbench/workSpace',
        meta: {
          title: '工作台',
        },
        component: () => import('@/views/dashboard/workSpace/index')
      }]
    },
    {
      name: '网站管理',
      path: '/official',
      redirect: '/official/blog',
      meta: {
        title: '网站管理',
        icon: 'shop'
      },
      component: () => import('@/views/dashboard/index'),
      children: [{
        name: '博客管理',
        path: '/official/blog',
        meta: {
          title: '博客管理',
        },
        component: () => import('@/views/dashboard/blog/index')
      },
      {
        name: '博客详情',
        path: '/official/blog/detail',
        meta: {
          title: '博客详情',
          hidden: true,
        },
        component: () => import('@/views/dashboard/blog/detail')
      },
      {
        name: '博客编辑',
        path: '/official/editor',
        meta: {
          title: '博客编辑',
          hidden: true
        },
        component: () => import('@/views/dashboard/blog/editor.vue')
      },
      {
        name: '相机库管理',
        path: '/official/camera',
        meta: {
          title: '相机库管理',
        },
        component: () => import('@/views/dashboard/camera/index')
      },
      {
        name: '教学视频管理',
        path: '/official/video',
        meta: {
          title: '教学视频管理',
        },
        component: () => import('@/views/dashboard/video/index')
      },
      {
        name: '视频上传',
        path: '/official/video/add',
        meta: {
          title: '视频截图导入',
          hidden: true,
        },
        component: () => import('@/views/dashboard/video/add')
      },
      {
        name: '视频详情',
        path: '/official/video/detail',
        meta: {
          title: '店铺详情',
          hidden: true,
        },
        component: () => import('@/views/dashboard/video/detail')
      }]
    }
  ]


}