export default {
    name: '团队管理',
    childKey: ['/team'],
    children: [
        {
            name: '团队管理',
            path: '/team',
            redirect: '/team/department',
            meta: {
                title: '团队管理',
                icon: 'team'
            },
            component: () => import('@/views/team/index'),
            children: [{
                name: '部门信息',
                path: '/team/department',
                meta: {
                    title: '部门信息',
                },
                component: () => import('@/views/team/department/index')
            },
            {
                name: '岗位列表',
                path: '/team/post',
                meta: {
                    title: '岗位列表',
                },
                component: () => import('@/views/team/post/index')
            },
            {
                name: '人员列表',
                path: '/team/stuff',
                meta: {
                    title: '人员列表',
                },
                component: () => import('@/views/team/stuff/index')
            },
            {
                name: '新增人员',
                path: '/team/stuff/add',
                meta: {
                    title: '新增人员',
                    hidden: true
                },
                component: () => import('@/views/team/stuff/add')
            },
            {
                name: '人员详情',
                path: '/team/stuff/detail',
                meta: {
                    title: '人员详情',
                    hidden: true
                },
                component: () => import('@/views/team/stuff/detail')
            },
            ]
        },
    ]
}