import {menuTree} from '@/api/login'
import routers from '@/router/modules/routers'

export default {
    nameSpace: 'layout',
    state: {
        routerCaches: [],
        userInfo: {}
    },
    getters: {
        routerList(state) {
            return state.routerCaches.flatMap(item => item.children)
        }
    },
    mutations: {
        ADD_ROUTER_CACHES(state, payload) {
            routerCaches.push(payload)
        },
        SET_ROUTER_CACHES_LAST_ONE(state) {
            state.routerCaches.push(state.routerCaches.splice(index, 1)[0]);
        },
        SET_ROUTER_CACHES(state, payload) {
            state.routerCaches = [...payload]
        },
        SET_USERINFO(state, payload) {
            state.userInfo = payload
        },
        INIT_STORE_STATE(state) {
            state.routerCaches = [],
                state.userInfo = {}
        },
    },
    actions: {
        async accessRouter({ state, commit }, payload) {
            return new Promise(async (resolve) => {
                const resTree = await menuTree()
                let permissionRouters = resTree.data.data
                let routerCaches = routers.map(item => {
                    const permissionRoutersArr = permissionRouters.map(it => it.menuUrl)

                    const data = item.children.filter(it => permissionRoutersArr.includes(it.path))

                    let children = data.map(it => {
                        let childes = it.children.filter(i => permissionRoutersArr.includes(i.path))
                        return {
                            ...it,
                            children: [...childes]
                        }
                    })
                    return {

                        ...item,
                        children: [...children]
                    }
                })

                commit('SET_ROUTER_CACHES', routerCaches)
                resolve(routerCaches)
            })

        },
    },

}