export default {
    name: '系统设置',
    childKey: ['/setting'],
    children: [
            {
                name: '系统设置',
                path: '/setting',
                redirect: '/setting/authority',
                meta: {
                    title: '系统设置',
                    icon: 'setting'
                },
                component: () => import('@/views/setting/index'),
                children: [{
                    name: '权限配置',
                    path: '/setting/authority',
                    meta: {
                        title: '权限配置',
                    },
                    component: () => import('@/views/setting/authority/index')
                },
                {
                    name: '菜单列表',
                    path: '/setting/menu',
                    meta: {
                        title: '菜单列表',
                    },
                    component: () => import('@/views/setting/menu/index')
                },
                {
                    name: '功能列表',
                    path: '/setting/use',
                    meta: {
                        title: '功能列表',
                    },
                    component: () => import('@/views/setting/use/index')
                }
                ]
            },
        ]
}